export const meditations = {
	body: "https://public.retreatsounds.com/meditations-preview/Body_Meditation.mp3",
	breath: "https://public.retreatsounds.com/meditations-preview/Breath_Meditation.mp3",
	davidWolfe: "https://public.retreatsounds.com/meditations-preview/David_Wolfe_Preview.mp3",
	arielle: "https://public.retreatsounds.com/meditations-preview/Dr_Arielle_Schwartz_Preview.mp3",
	shefali: "https://public.retreatsounds.com/meditations-preview/Dr_Shefali_Tsabary.mp3",
	guided: "https://public.retreatsounds.com/meditations-preview/Guided_Meditation.mp3",
	heart: "https://public.retreatsounds.com/meditations-preview/Heart_Meditation.mp3",
	johnGray: "https://public.retreatsounds.com/meditations-preview/John_Gray_Preview.mp3",
	krishnaDas: "https://public.retreatsounds.com/meditations-preview/Krishna_Das_Preview.mp3",
	michaelBeckwith: "https://public.retreatsounds.com/meditations-preview/Michael_Beckwith's_Immunity_Meditation.mp3",
	timed: "https://public.retreatsounds.com/meditations-preview/Timed_Meditations.mp3",
	freedom: "https://public.retreatsounds.com/meditations-preview/freedom_preview.wav",
	ignite: "https://public.retreatsounds.com/meditations-preview/ignite_preview.wav",
	forgive: "https://public.retreatsounds.com/meditations-preview/forgive_preview.wav",
	restAndRelax: "https://public.retreatsounds.com/meditations-preview/restAndRelax_preview.wav",
	easyThoughts: "https://public.retreatsounds.com/meditations-preview/easyThoughts_preview.wav",
	openHeart: "https://public.retreatsounds.com/meditations-preview/openHeart_preview.wav",

}

