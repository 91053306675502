import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from "react-router-dom";

let intervalForward = null;
let intervalReverse = null;
let counter = 1;

const PaymentSuccess = (props) => {
  const navigate = useNavigate();
  useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		startForwardInterval();
		return () => {
			window.removeEventListener('scroll', handleScroll);
		}
	}, []);

	const startForwardInterval = () => {
		intervalForward = setInterval(() => {
			// console.log('[COUNTER counter]', counter);
			const elementToCheck = document.getElementById(`headerAbsImgCircle${counter}`);
			if (elementToCheck) {
				// document.getElementById(`headerAbsImg`);
				elementToCheck.classList.add('show');
			}
			counter = counter + 1;
			if (counter > 5) {
				clearInterval(intervalForward);
				startReverseInterval();
			}
		}, 400);
	}

	const startReverseInterval = () => {
		intervalReverse = setInterval(() => {
			// console.log('[COUNTER counter]', counter);
			const elementToCheck = document.getElementById(`headerAbsImgCircle${counter}`);
			if (elementToCheck) {
				// document.getElementById(`headerAbsImg`);
				elementToCheck.classList.remove('show');
			}
			counter = counter - 1;
			if (counter <= 0) {
				clearInterval(intervalReverse);
				startForwardInterval();
			}
		}, 400);
	}

	const handleScroll = (event) => {
		let scrollTop = document.documentElement.scrollTop;
		if (scrollTop > 130 && !document.body.classList.contains('header-fixed')) {
			document.body.classList.add('header-fixed');
		}
		if (scrollTop <= 130) {
			document.body.classList.remove('header-fixed');
		}
	}

  const goBackToRs = () => {

    navigate("/")
  }

  return (
    <div className="site-container">
      <div className="h-d-outer-cont">
        <div className="h-d-inner-cont no-pad-bottom">
          <Header />
        </div>
      </div>
      <div className="payment-status-cont">
        <div className="payment-status-box d-flex justify-content-center align-items-center flex-column">
          <FontAwesomeIcon className="payment-status-box-icon yellow" icon={faInfoCircle} />
          <div className="payment-status-box-header">Payment Failed</div>
          {/* <div className="payment-status-box-cont">
            <ol>
              <li>Check your inbox for Retreat Sounds login Credentials</li>
              <li>Install the App and enter credentials from Step 1<br/><strong>Play Store</strong>: <a href="#">Download Retreat Android App</a><br/><strong>App Store</strong>: <a href="#">Download Retreat IOS App</a></li>
            </ol>
          </div> */}
          <div className="payment-status-box-go-back-cont">
            <button onClick={goBackToRs} className="app-button purple big d-flex justify-content-center align-items-center">
              <div className="app-button-text more-l-s">Go back to retreatsounds.com</div>
            </button>
          </div>
        </div>
      </div>
      <Footer hidePattern={true} />
    </div>
  );
};
export default PaymentSuccess;
