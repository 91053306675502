import { Link } from "react-router-dom";
import "../App.css";
export const Header = (props) => {
    const headerContent = () => {
        return (
            <div className="main-site-wrapper">
                <div className="h-d-top-cont-inner-cont d-flex align-items-center justify-content-start">
                    <Link to="/" className="h-d-top-link">
                        <img
                            src="./imagesV2/logo/logo.png"
                            className="h-d-top-image"
                        />
                    </Link>
                    <div className="h-d-top-button-cont">
                        <button
                            onClick={() =>
                                document
                                    .getElementById("pricing-section")
                                    .scrollIntoView(true)
                            }
                            className="app-button d-flex justify-content-center align-items-center"
                        >
                            <div className="app-button-text">Try It Free</div>
                        </button>
                    </div>
                </div>
            </div>
        );
    };
    return (
        <>
            <div className="h-d-top-cont">{headerContent()}</div>
            <div className="h-d-top-cont-invisible">{headerContent()}</div>
        </>
    );
};
