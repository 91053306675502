import CookieConsent, { Cookies } from "react-cookie-consent";
import { Link } from "react-router-dom";
export const Footer = (props) => {
    return (
        <div className="f-o-o-cont">
            <div
                className={`f-o-o-outer-cont ${
                    props.hidePattern ? "less-pad-top" : ""
                }`}
            >
                {!props.hidePattern ? (
                    <div className="f-o-o-abs-image-cont"></div>
                ) : null}
                <div className="f-o-o-inner-cont">
                    <div className="main-site-wrapper">
                        <div className="f-o-o-top-cont">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12 col-lg-4">
                                        <div className="f-o-o-top-left d-flex justify-content-center align-items-center">
                                            <div className="d-lg-flex flex-lg-row">
                                                <div className="f-o-o-top-left-logo-cont">
                                                    <img
                                                        src="./imagesV2/footer-logo.png"
                                                        className="f-o-o-top-left-logo-image"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-3">
                                        <div className="f-o-o-top-left d-flex justify-content-center justify-content-lg-start align-items-center">
                                            <div className="d-lg-flex flex-lg-row">
                                                <div className="f-o-o-top-left-btns-cont align-items-center align-items-lg-start d-flex flex-column flex-lg-column">
                                                    <button
                                                        className="f-o-o-top-left-button"
                                                        type=""
                                                        onClick={() =>
                                                            window
                                                                .open(
                                                                    "https://apps.apple.com/us/app/retreat-sounds/id1499208414",
                                                                    "_blank"
                                                                )
                                                                .focus()
                                                        }
                                                    >
                                                        <img
                                                            src="./images/footer/app-store.png"
                                                            className="f-o-o-top-left-button-image"
                                                        />
                                                    </button>
                                                    <button
                                                        className="f-o-o-top-left-button"
                                                        type=""
                                                        onClick={() =>
                                                            window
                                                                .open(
                                                                    "https://play.google.com/store/apps/details?id=com.nutririse.retreat.android",
                                                                    "_blank"
                                                                )
                                                                .focus()
                                                        }
                                                    >
                                                        <img
                                                            src="./images/footer/google-play.png"
                                                            className="f-o-o-top-left-button-image"
                                                        />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-5">
                                        <div className="f-o-o-top-right d-flex justify-content-center align-items-center align-items-lg-start flex-column">
                                            <div className="f-o-o-top-right-title">
                                                Make your inbox vibrate higher
                                            </div>
                                            <div className="f-o-o-top-right-subtitle">
                                                Get the latest Retreat content,
                                                tips, and news
                                            </div>
                                            <div className="f-o-o-top-right-content-cont d-flex flex-column flex-lg-row align-items-center align-items-lg-start">
                                                <div className="f-o-o-top-right-text-f-cont d-flex flex-fill">
                                                    <input
                                                        type="email"
                                                        className="f-o-o-top-right-text-f"
                                                        placeholder="Your email"
                                                    />
                                                </div>
                                                <button className="app-button big purple d-flex justify-content-center align-items-center f-o-o-top-right-text-button">
                                                    <div className="app-button-text more-l-s">
                                                        Sign Up
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="f-o-o-bottom-cont">
                            <div className="f-o-o-bottom-inner-cont d-flex flex-column justify-content-center align-items-center">
                                <div className="f-o-o-bottom-top-cont d-flex flex-row">
                                    <button
                                        type="button"
                                        className="f-o-o-social-button"
                                    >
                                        <img
                                            src="./images/footer/fb.png"
                                            className="f-o-o-social-button-image"
                                        />
                                    </button>
                                    <button
                                        type="button"
                                        className="f-o-o-social-button"
                                    >
                                        <img
                                            src="./images/footer/instagram.png"
                                            className="f-o-o-social-button-image"
                                        />
                                    </button>
                                    <button
                                        type="button"
                                        className="f-o-o-social-button"
                                    >
                                        <img
                                            src="./images/footer/twitter.png"
                                            className="f-o-o-social-button-image"
                                        />
                                    </button>
                                    <button
                                        type="button"
                                        className="f-o-o-social-button"
                                    >
                                        <img
                                            src="./images/footer/yt.png"
                                            className="f-o-o-social-button-image no-width"
                                        />
                                    </button>
                                </div>
                                <div className="f-o-o-bottom-reserved top">
                                    Retreat Institution Â© 2020. All Rights
                                    Reserved
                                </div>
                                <div className="f-o-o-bottom-reserved">
                                    Contact us at{" "}
                                    <a href="mailto:support@retreatsounds.com">
                                        support@retreatsounds.com
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CookieConsent
                location="bottom"
                buttonText="Accept"
                enableDeclineButton={true}
                declineButtonText="Reject"
                cookieName="cookieconsentretreat"
                style={{ background: "#714694" }}
                // buttonStyle={{ }}
                buttonClasses="cookie-accept-button"
                declineButtonClasses="cookie-reject-button"
                hideOnDecline={false}
                // declineButtonClasses={[]}
                expires={150}
            >
                This website uses cookies to enhance the user experience{" "}
                <Link style={{ color: "#C2F7EA" }} to="/cookies">
                    Learn more
                </Link>
            </CookieConsent>
        </div>
    );
};
